import { RouteRecordRaw } from "vue-router";
import { RCompaniesBrowse } from "@/pages/Layout/views/Expert/Companies/browse/RCompaniesBrowse";
import { RCompaniesTemplate } from "@/pages/Layout/views/Expert/Companies/template/RCompaniesTemplate";

export const RCompanies: RouteRecordRaw = {
  path: "companies",
  name: "Companies",
  component: () =>
    import(
      /* webpackChunkName: "companies" */
      "./VCompanies.vue"
    ),
  meta: {
    title: "Companies",
  },
  children: [RCompaniesBrowse, RCompaniesTemplate],
};