import { createRouter, createWebHistory } from "vue-router";
import type { RouteLocationRaw, NavigationFailure } from "vue-router";
import { useRoutesChecker } from "./utils/guard";
import { routes } from "./routes";

const router = createRouter({
  history: createWebHistory(),
  routes,
});

useRoutesChecker(router);

const originalPush = router.push;

router.beforeEach((to, from, next) => {
  if (from.fullPath) {
    sessionStorage.setItem("previousPath", from.fullPath);
  }
  
  next();
});

router.push = function push(
  location: RouteLocationRaw,
  onResolve?: Function | undefined | any,
  onReject?: Function | undefined
): Promise<void | NavigationFailure> {
  if (onResolve || onReject) {
    return (originalPush.call(
      this,
      location
    ) as unknown) as Promise<void | NavigationFailure>;
  }

  return ((originalPush.call(
    this,
    location
  ) as unknown) as Promise<void | NavigationFailure>).catch((err: Error) => {
    const reg = new RegExp(
      "^Redirected when going from \"[a-z_.\\/]+\" to \"[a-z_.\\/]+\" via a navigation guard.$"
    );

    if (reg.test(err.message))
      return (Promise.resolve(false) as unknown) as void | NavigationFailure;

    return (Promise.reject(err) as unknown) as void | NavigationFailure;
  }) as Promise<void | NavigationFailure>;
};

export default router;

