import { RouteRecordRaw } from "vue-router";
import { RCandidateMatchesBrowse } from "@/pages/Layout/views/Expert/Candidates/template/views/CandidateMatches/browse/RCandidateMatchesBrowse";
import { RCandidateMatchesTemplate } from "@/pages/Layout/views/Expert/Candidates/template/views/CandidateMatches/template/RCandidateMatchesTemplate";

export const RCandidateMatches: RouteRecordRaw = {
  path: "matches",
  name: "CandidateMatches",
  component: () =>
    import(
      /* webpackChunkName: "candidate-matches" */
      "./VCandidateMatches.vue"
    ),
  children: [RCandidateMatchesBrowse, RCandidateMatchesTemplate],

};
