import { RouteRecordRaw } from "vue-router";
import { RCompanyDetails } from "@/pages/Layout/views/Expert/Vacancies/template/views/VacancyCompanyDetails/RCompanyDetails";
import { RVacancyCandidates } from "@/pages/Layout/views/Expert/Vacancies/template/views/VacancyCandidates/RVacancyCandidates";
import { RVacancyDetails } from "@/pages/Layout/views/Expert/Vacancies/template/views/VacancyDetails/RVacancyDetails";
import { RVacancyMatches } from "@/pages/Layout/views/Expert/Vacancies/template/views/VacancyMatches/RVacancyMatches";
import { RVacancyMatchesRejected } from "@/pages/Layout/views/Expert/Vacancies/template/views/VacancyMatchesRejected/RVacancyMatchesRejected";

export const RExpertVacanciesTemplate: RouteRecordRaw = {
  path: ":vacancy_id(\\d+)",
  name: "ExpertVacanciesTemplate",
  component: () =>
    import(
      /* webpackChunkName: "expert-vacancy-template" */
      "./VExpertVacanciesTemplate.vue"
    ),
  children: [
    RCompanyDetails,
    RVacancyCandidates,
    RVacancyDetails,
    RVacancyMatches,
    RVacancyMatchesRejected,
  ],
};
