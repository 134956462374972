import { RouteRecordRaw } from "vue-router";

export const RExpertCandidatesBrowse: RouteRecordRaw = {
  path: "",
  name: "ExpertCandidatesBrowse",
  component: () =>
    import(
      /* webpackChunkName: "expert-candidates-browse" */
      "./VExpertCandidatesBrowse.vue"
    ),
};

