import { RouteRecordRaw } from "vue-router";
import { RJobsBrowse } from "@/pages/Layout/views/Employer/Jobs/JobsBrowse/RJobsBrowse";
import { RJobMatch } from "@/pages/Layout/views/Employer/Jobs/JobMatch/RJobMatch";
import { RJob } from "@/pages/Layout/views/Employer/Jobs/Job/RJob";

export const REmployerJobs: RouteRecordRaw = {
  path: "jobs",
  name: "EmployerJobs",
  component: () =>
    import(
      /* webpackChunkName: "employer-jobs" */
      "./VEmployerJobs.vue"
    ),
  children: [
    RJobsBrowse,
    RJobMatch,
    RJob
  ],
  meta: {
    title: "My jobs",
  },
};