import { RouteRecordRaw } from "vue-router";

export const RResetPassword: RouteRecordRaw = {
  path: "password_reset",
  name: "ResetPassword",
  component: () =>
    import(
      /* webpackChunkName: "auth-reset-password" */
      "./VResetPassword.vue"
    ),
};
