import { createAuth } from "@websanova/vue-auth";
import driverHttpAxios from "@websanova/vue-auth/dist/drivers/http/axios.1.x.esm.js";
import driverRouterVueRouter from "@websanova/vue-auth/dist/drivers/router/vue-router.2.x.esm.js";

import router from "@/router/index";
import axios from "@/plugins/http";

const auth = createAuth({
  // currentToken: "token",
  plugins: {
    http: axios,
    router: router,
  },
  drivers: {
    http: driverHttpAxios,
    router: driverRouterVueRouter,
    auth: {
      request(req: any, token: any) {
        driverHttpAxios.setHeaders.call(this, req, {
          Authorization: `Bearer ${token}`,
        });
      },
      response(res: any) {
        return (res.data || {}).token;
      },
    },
  },
  options: {
    rememberkey: "token",
    tokenDefaultKey: "token",
    stores: ["storage", "cookie"],
    authRedirect: { path: "/auth/login" },
    notFoundRedirect: "/404",
    rolesKey: "type",
    loginData: {
      url: "/auth/login",
      redirect: false,
      fetchUser: false,
      staySignedIn: true,
    },
    logoutData: {
      // url: "/auth/logout",
      // method: "POST",
      redirect: "/auth/login",
      makeRequest: false,
    },
    refreshData: {
      url: "auth/refresh",
      method: "GET",
      enabled: true,
      interval: 10, // minutes
    },
    fetchData: {
      enabled: false,
    },
  },
});

export default auth;