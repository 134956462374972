import { RouteRecordRaw } from "vue-router";
import { createRouterLinks } from "@/models/router";

import { REmployerQuestionnaire } from "@/pages/Layout/views/Employer/Questionnaire/REmployerQuestionnaire";
import { REmployerJobs } from "@/pages/Layout/views/Employer/Jobs/REmployerJobs";
import { REmployerProfile } from "@/pages/Layout/views/Employer/Profile/REmployerProfile";

// import { REmployerVacancies } from "@/pages/Layout/views/Employer/Vacancies/REmployerVacancies";

export const REmployer: RouteRecordRaw = {
  path: "company",
  name: "Employer",
  component: () =>
    import(
      /* webpackChunkName: "employer" */
      "./VEmployer.vue"
    ),
  children: [
    REmployerQuestionnaire,
    REmployerJobs,
    REmployerProfile,
    // REmployerVacancies
  ],
};

export const employerLinks = createRouterLinks(REmployer);
