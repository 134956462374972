import { RouteRecordRaw } from "vue-router";

export const RVacancyMatchesTemplate: RouteRecordRaw = {
  path: ":match_id(\\d+)",
  name: "VacancyMatchesTemplate",
  component: () =>
    import(
      /* webpackChunkName: "vacancy-matches-template" */
      "./VVacancyMatchesTemplate.vue"
    ),
};
