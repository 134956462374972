import { RouteRecordRaw } from "vue-router";
import { RJobTemplateView } from "@/pages/Layout/views/Employer/Jobs/Job/JobTemplateView/RJobTemplateView";
import { RJobTemplateEdit } from "@/pages/Layout/views/Employer/Jobs/Job/JobTemplateEdit/RJobTemplateEdit";
import { RJobPayment } from "@/pages/Layout/views/Employer/Jobs/Job/JobPayment/RJobPayment";

export const RJob: RouteRecordRaw = {
  path: ":job_id(\\d+)",
  name: "Job",
  component: () =>
    import(
      /* webpackChunkName: "job-template" */
      "./VJob.vue"
    ),
  children: [
    RJobTemplateView,
    RJobTemplateEdit,
    RJobPayment,
  ]
};
