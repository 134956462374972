import { RouteRecordRaw } from "vue-router";

export const RJobsView: RouteRecordRaw = {
  path: "jobs/view/:job_id(\\d+)",
  name: "JobsView",
  component: () =>
    import(
      /* webpackChunkName: "jobs-view" */
      "./VJobsView.vue"
    ),
};
