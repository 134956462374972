import { RouteRecordRaw } from "vue-router";

export const RJobPaymentFree: RouteRecordRaw = {
  path: "free",
  name: "JobPaymentFree",
  component: () =>
    import(
      /* webpackChunkName: "job-payment-free" */
      "./VJobPaymentFree.vue"
    )
};
