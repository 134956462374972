import { RouteRecordRaw } from "vue-router";
import { RExpertCandidatesBrowse } from "@/pages/Layout/views/Expert/Candidates/browse/RExpertCandidatesBrowse";
import { RExpertCandidatesTemplate } from "@/pages/Layout/views/Expert/Candidates/template/RExpertCandidatesTemplate";

export const RExpertCandidates: RouteRecordRaw = {
  path: "candidates",
  name: "ExpertCandidates",
  component: () =>
    import(
      /* webpackChunkName: "expert-candidates" */
      "./VExpertCandidates.vue"
    ),
  meta: {
    title: "Candidates",
  },
  children: [RExpertCandidatesBrowse, RExpertCandidatesTemplate],
};
