import { RouteRecordRaw } from "vue-router";

export const RJobMatchCandidate: RouteRecordRaw = {
  path: "candidate/:match_id(\\d+)",
  name: "JobMatchCandidate",
  component: () =>
    import(
      /* webpackChunkName: "job-match-candidate" */
      "./VJobMatchCandidate.vue"
    )
};