import { RouteRecordRaw, RouteRecord } from "vue-router";
import { RLogin } from "./views/Login/RLogin";
import { RResetPassword } from "./views/ResetPassword/RResetPassword";
import { RVerification } from "./views/Verification/RVerification";
import { RSuccess } from "./views/Success/RSuccess";
import { RTest } from "./views/Test/RTest";

export const RAuth: RouteRecordRaw = {
  path: "/auth",
  component: () =>
    import(
      /* webpackChunkName: "auth" */
      "./PAuth.vue"
    ),
  children: [
    {
      path: "",
      redirect: (RLogin as RouteRecord).path,
    },
    RResetPassword,
    RLogin,
    RVerification,
    RSuccess,
    RTest,
  ],
};
