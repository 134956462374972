import { RouteRecordRaw } from "vue-router";
import { RJobPaymentError } from "@/pages/Layout/views/Employer/Jobs/Job/JobPayment/JobPaymentError/RJobPaymentError";
import { RJobPaymentSuccess } from "@/pages/Layout/views/Employer/Jobs/Job/JobPayment/JobPaymentSuccess/RJobPaymentSuccess";
import { RJobPaymentFree } from "@/pages/Layout/views/Employer/Jobs/Job/JobPayment/JobPaymentFree/RJobPaymentFree";
import { RJobPaymentView } from "@/pages/Layout/views/Employer/Jobs/Job/JobPayment/JobPaymentView/RJobPaymentView";

export const RJobPayment: RouteRecordRaw = {
  path: "payment",
  name: "JobPayment",
  component: () =>
    import(
      /* webpackChunkName: "job-paymanet" */
      "./VJobPayment.vue"
    ),
  children: [
    RJobPaymentError,
    RJobPaymentSuccess,
    RJobPaymentFree,
    RJobPaymentView,
  ]
};