import { RouteRecordRaw } from "vue-router";

export const RGuide1: RouteRecordRaw = {
  path: ":slug",
  name: "Academy",
  component: () =>
    import(
      /* webpackChunkName: "layout" */
      "./PGuide1.vue"
    ),
  children: [
  ]
  // meta: {
  //   auth: true,
  // },
};
