import { RouteRecordRaw } from "vue-router";

export const RJobsBrowse: RouteRecordRaw = {
  path: "",
  name: "JobsBrowse",
  component: () =>
    import(
      /* webpackChunkName: "jobs-browse" */
      "./VJobsBrowse.vue"
    )
};
