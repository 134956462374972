import { RouteRecordRaw } from "vue-router";

export const RLogin: RouteRecordRaw = {
  path: "login",
  name: "Login",
  component: () =>
    import(
      /* webpackChunkName: "auth-login" */
      "./VLogin.vue"
    ),
};
