import { RouteRecordRaw } from "vue-router";
import { createRouterLinks } from "@/models/router";
import { RExpertCandidates } from "@/pages/Layout/views/Expert/Candidates/RExpertCandidates";
import { RExpertVacancies } from "@/pages/Layout/views/Expert/Vacancies/RExpertVacancies";
import { RCompanies } from "@/pages/Layout/views/Expert/Companies/RCompanies";

export const RExpert: RouteRecordRaw = {
  path: "expert",
  name: "Expert",
  component: () =>
    import(
      /* webpackChunkName: "expert" */
      "./VExpert.vue"
    ),
  redirect: { name: "RExpertCandidates" }, 
  children: [
    RExpertCandidates,
    RExpertVacancies,
    RCompanies,
  ],
};

export const expertLinks = createRouterLinks(RExpert);