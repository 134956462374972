import { RouteRecordRaw } from "vue-router";

export const RError404: RouteRecordRaw = {
  path: "/:pathMatch(.*)*",
  name: "Error404",
  component: () =>
    import(
      /* webpackChunkName: "error-404" */
      "./PError404.vue"
    ),
};
