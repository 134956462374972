import { RouteRecordRaw } from "vue-router";

export const RSuccess: RouteRecordRaw = {
  path: "success",
  name: "Success",
  component: () =>
    import(
      /* webpackChunkName: "auth-success" */
      "./VSuccess.vue"
    ),
};
