import { RouteRecordRaw } from "vue-router";
import { RError503 } from "@/pages/Error/views/Error503/RError503";
import { RError404 } from "@/pages/Error/views/Error404/RError404";
import { RError403 } from "@/pages/Error/views/Error403/RError403";

export const RError: RouteRecordRaw = {
  path: "/error",
  name: "Error",
  component: () =>
    import(
      /* webpackChunkName: "error" */
      "./PError.vue"
    ),
  children: [
    RError503,
    RError404,
    RError403,
  ],
};
