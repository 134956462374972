import { RouteRecordRaw } from "vue-router";

export const RError503: RouteRecordRaw = {
  path: "503",
  name: "Error503",
  component: () =>
    import(
      /* webpackChunkName: "error-503" */
      "./PError503.vue"
    ),
};
