import { RouteRecordRaw } from "vue-router";

export const RCompaniesBrowse: RouteRecordRaw = {
  path: "",
  name: "ExpertCompaniesBrowse",
  component: () =>
    import(
      /* webpackChunkName: "expert-comanies-browse" */
      "./VCompaniesBrowse.vue"
    ),
};