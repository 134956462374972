import { RouteRecordRaw } from "vue-router";

export const RPrintProfile: RouteRecordRaw = {
  path: "/print/:candidate_id(\\d+)",
  name: "PrintProfile",
  component: () =>
    import(
      /* webpackChunkName: "print-profile" */
      "./VPrintProfile.vue"
    ),
};
