import { RouteRecordRaw } from "vue-router";

export const RJobPaymentView: RouteRecordRaw = {
  path: "",
  name: "JobPaymentView",
  component: () =>
    import(
      /* webpackChunkName: "job-payment-view" */
      "./VJobPaymentView.vue"
    )
};