import { RouteRecordRaw } from "vue-router";

export const RExpertVacanciesBrowse: RouteRecordRaw = {
  path: "",
  name: "ExpertVacanciesBrowse",
  component: () =>
    import(
      /* webpackChunkName: "expert-vacancies-browse" */
      "./VExpertVacanciesBrowse.vue"
    ),
};

