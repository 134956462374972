import { RouteRecordRaw } from "vue-router";

export const RCandidateDetails: RouteRecordRaw = {
  path: "candidate-details",
  name: "CandidateDetails",
  component: () =>
    import(
      /* webpackChunkName: "candidate-details" */
      "./VCandidateDetails.vue"
    ),
};
