import { RouteRecordRaw } from "vue-router";
import { RCandidateDetails } from "@/pages/Layout/views/Expert/Candidates/template/views/CandidateDetails/RCandidateDetails";
import { RCandidateMatches } from "@/pages/Layout/views/Expert/Candidates/template/views/CandidateMatches/RCandidateMatches";
import { RCandidateVacancies } from "@/pages/Layout/views/Expert/Candidates/template/views/CandidateVacancies/RCandidateVacancies";

export const RExpertCandidatesTemplate: RouteRecordRaw = {
  path: ":candidate_id(\\d+)",
  name: "ExpertCandidatesTemplate",
  component: () =>
    import(
      /* webpackChunkName: "expert-candidates-template" */
      "./VExpertCandidatesTemplate.vue"
    ),
  children: [
    RCandidateDetails,
    RCandidateMatches,
    RCandidateVacancies,
  ],
};
