import { RouteRecordRaw } from "vue-router";

export const RVacancyMatchesBrowse: RouteRecordRaw = {
  path: "",
  name: "VacancyMatchesBrowse",
  component: () =>
    import(
      /* webpackChunkName: "vacancy-matches-browse" */
      "./VVacancyMatchesBrowse.vue"
    ),
};
