import { RouteRecordRaw } from "vue-router";

export const RError403: RouteRecordRaw = {
  path: "/403",
  name: "Error403",
  component: () =>
    import(
      /* webpackChunkName: "error-403" */
      "./PError403.vue"
    ),
};
