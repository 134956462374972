import { RouteRecordRaw } from "vue-router";

export const RVacancyMatchesRejected: RouteRecordRaw = {
  path: "match-rejected",
  name: "VacancyMatchesRejected",
  component: () =>
    import(
      /* webpackChunkName: "vacancy-matches-rejected" */
      "./VVacancyMatchesRejected.vue"
    ),
};
