import { RouteRecordRaw } from "vue-router";

export const RJobTemplateEdit: RouteRecordRaw = {
  path: "edit",
  name: "JobTemplateEdit",
  component: () =>
    import(
      /* webpackChunkName: "job-template-edit" */
      "./VJobTemplateEdit.vue"
    )
};
