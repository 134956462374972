import { RouteRecordRaw } from "vue-router";

export const RJobTemplateView: RouteRecordRaw = {
  path: "view",
  name: "JobTemplateView",
  component: () =>
    import(
      /* webpackChunkName: "job-template-view" */
      "./VJobTemplateView.vue"
    )
};
