import { RouteRecordRaw } from "vue-router";
import { createRouterLinks } from "@/models/router";
import { RQuestionnaire } from "@/pages/Layout/views/Candidate/Questionnaire/RQuestionnaire";
import { RProfile } from "@/pages/Layout/views/Candidate/Profile/RProfile";
import { RMyJobs } from "@/pages/Layout/views/Candidate/MyJobs/RMyJobs";
import { RRecommended } from "@/pages/Layout/views/Candidate/Recommended/RRecommended";
import { RJobsView } from "@/pages/Layout/views/Candidate/Jobs/View/RJobsView";

export const RCandidate: RouteRecordRaw = {
  path: "candidate",
  name: "Candidate",
  component: () =>
    import(
      /* webpackChunkName: "candidate" */
      "./VCandidate.vue"
    ),
  redirect: { name: "Profile" }, 
  children: [
    RQuestionnaire,
    RMyJobs,
    RRecommended,
    RProfile,

    // TODO: finish refactoring
    RJobsView,
  ]
};

export const candidateLinks = createRouterLinks(RCandidate);