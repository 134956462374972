import { RouteRecordRaw } from "vue-router";

export const RVacancyCandidatesTemplate: RouteRecordRaw = {
  path: ":candidate_id(\\d+)",
  name: "VacancyCandidatesTemplate",
  component: () =>
    import(
      /* webpackChunkName: "vacancy-candidates-template" */
      "./VVacancyCandidatesTemplate.vue"
    ),
};
