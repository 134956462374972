import { RouteRecordRaw } from "vue-router";

export type TRouterLink = {
  path: string;
  label: string;
  icon?: string;
  basePath: string;
};

const createRouterLink = (route: RouteRecordRaw, routeBase?: string): TRouterLink | null => {
  const { path, meta } = route;

  if (meta && (meta.title || meta.icon)) {
    return {
      path: (routeBase ? "/" + routeBase : "") + (path ? "/" + path : ""),
      label: meta.title as string,
      icon: (meta.icon ?? "") as string,
      basePath: path,
    };
  }

  return null;
};

export const createRouterLinks = (route: RouteRecordRaw): TRouterLink[] => {
  const routerLinks = (route.children ?? [])
    .map((child) => createRouterLink(child, route.path))
    .filter((route): route is TRouterLink => !!route);

  return routerLinks;
};