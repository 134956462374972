import { RouteRecordRaw } from "vue-router";

export const REmployerProfile: RouteRecordRaw = {
  path: "profile",
  name: "EmployerProfile",
  component: () =>
    import(
      /* webpackChunkName: "employer-profile" */
      "./VEmployerProfile.vue"
    ),
  children: [],
  meta: {
    title: "Company profile",
  },
};