import { RouteRecordRaw } from "vue-router";
import { RJobMatchCandidate } from "@/pages/Layout/views/Employer/Jobs/JobMatch/JobMatchCandidate/RJobMatchCandidate";
import { RJobMatchBrowse } from "@/pages/Layout/views/Employer/Jobs/JobMatch/JobMatchBrowse/RJobMatchBrowse";

export const RJobMatch: RouteRecordRaw = {
  path: "match/:job_id(\\d+)",
  name: "RJobMatch",
  component: () =>
    import(
      /* webpackChunkName: "job-match" */
      "./VJobMatch.vue"
    ),
  children: [
    RJobMatchCandidate,
    RJobMatchBrowse,
  ],
};