import { createApp } from "vue";
import { createPinia } from "pinia";

import install from "@betternations/ui-kit";

import App from "./App.vue";
import router from "./router";
import auth from "@/plugins/auth";

import PrimeVue from "primevue/config";
import Aura from "@primevue/themes/aura";
import ToastService from "primevue/toastservice";

import "primeicons/primeicons.css";
import "./assets/styles/app.sass";
import "./assets/styles/theme/viva-light.css";

import mixpanel from "@/plugins/mixpanel";

import VueLazyLoad from "vue3-lazyload";

const app = createApp(App);

app.use(PrimeVue, {
  theme: {
    preset: Aura,
    options: {
      darkModeSelector: ".my-app-dark"
    }
  }
});

app.use(ToastService);

app.use(mixpanel);

app.use(createPinia());

app.use(auth);

app.use(router);

app.use(install);

app.use(VueLazyLoad, {});

app.mount("#app");
