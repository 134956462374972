import { RouteRecordRaw } from "vue-router";

export const REmployerQuestionnaire: RouteRecordRaw = {
  path: "questionnaire",
  name: "EmployerQuestionnaire",
  component: () =>
    import(
      /* webpackChunkName: "employer-questionnaire" */
      "./VEmployerQuestionnaire.vue"
    ),
};
