import { RouteRecordRaw } from "vue-router";

export const RJobPaymentSuccess: RouteRecordRaw = {
  path: "success",
  name: "JobPaymentSuccess",
  component: () =>
    import(
      /* webpackChunkName: "job-payment-success" */
      "./VJobPaymentSuccess.vue"
    )
};
