import { RouteRecordRaw } from "vue-router";

export const RVerification: RouteRecordRaw = {
  path: "verification",
  name: "Verification",
  component: () =>
    import(
      /* webpackChunkName: "auth-verification" */
      "./VVerification.vue"
    ),
};
