import { RouteRecordRaw } from "vue-router";

export const RProfile: RouteRecordRaw = {
  path: "profile",
  name: "Profile",
  component: () =>
    import(
      /* webpackChunkName: "profile" */
      "./VProfile.vue"
    ),
  meta: {
    title: "Profile",
    icon: "user-profile-circle"
  },
};