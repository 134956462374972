import { RouteRecordRaw } from "vue-router";

export const RVacancyDetails: RouteRecordRaw = {
  path: "vacancy-details",
  name: "VacancyDetails",
  component: () =>
    import(
      /* webpackChunkName: "vacancy-details" */
      "./VVacancyDetails.vue"
    ),
};
