import { RouteRecordRaw } from "vue-router";

export const RTest: RouteRecordRaw = {
  path: "test",
  name: "Test",
  component: () =>
    import(
      /* webpackChunkName: "auth-test" */
      "./VTest.vue"
    ),
};
