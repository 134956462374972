import { RouteRecordRaw } from "vue-router";
import { RExpertVacanciesBrowse } from "@/pages/Layout/views/Expert/Vacancies/browse/RExpertVacanciesBrowse";
import { RExpertVacanciesTemplate } from "@/pages/Layout/views/Expert/Vacancies/template/RExpertVacanciesTemplate";

export const RExpertVacancies: RouteRecordRaw = {
  path: "vacancies",
  name: "ExpertVacancies",
  component: () =>
    import(
      /* webpackChunkName: "expert-vacancies" */
      "./VExpertVacancies.vue"
    ),
  meta: {
    title: "Vacancies",
  },
  children: [RExpertVacanciesBrowse, RExpertVacanciesTemplate],
};
