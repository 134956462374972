import { RouteRecordRaw } from "vue-router";
import { RVacancyCandidatesTemplate } from "@/pages/Layout/views/Expert/Vacancies/template/views/VacancyCandidates/template/RVacancyCandidatesTemplate";

export const RVacancyCandidates: RouteRecordRaw = {
  path: "candidates",
  name: "VacancyCandidates",
  component: () =>
    import(
      /* webpackChunkName: "vacancy-candidates" */
      "./VVacancyCandidates.vue"
    ),
  children: [
    RVacancyCandidatesTemplate,
  ],
};
