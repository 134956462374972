import { RouteRecordRaw } from "vue-router";
import { RGuide1 } from "./Guides/RGuide1";

export const RAcademy: RouteRecordRaw = {
  path: "/academy",
  name: "Academy",
  component: () =>
    import(
      /* webpackChunkName: "layout" */
      "./PAcademy.vue"
    ),
  children: [
    {
      path: "/academy",
      redirect: "/academy/how-to-hire-the-best-talent-for-your-organisation"
    },
    RGuide1
  ]
  // meta: {
  //   auth: true,
  // },
};
