import axios from "axios";
import router from "@/router";
import auth from "@/plugins/auth";
import { HttpStatusCodes } from "@/constatnts/httpStatusCodes";

const ROUTE_NAME_LOGIN = "Login";

const PATH_403 = "/error/403";

axios.defaults.baseURL = import.meta.env.VITE_API_URL;
axios.defaults.headers.post["Content-Type"] = "application/json";
axios.defaults.headers.post["Accept"] = "application/json";

axios.interceptors.request.use(
  async (config) => {
    const token = localStorage.getItem("token");

    if (!token) return config;

    config.headers.token = token;

    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  function (response) {
    if (!response?.data) return response;
    
    const { token } = response.data as any;

    if (!token) return response;

    axios.defaults.headers.common["token"] = token;

    localStorage.setItem("token", token);

    return response;
  },
  async function (error) {
    if (
      error.response?.status === HttpStatusCodes.UNAUTHORIZED
    ) {
      auth.logout({
        makeRequest: false,
        redirect: { name: ROUTE_NAME_LOGIN },
      });
    } else if (
      error.response?.status === HttpStatusCodes.FORBIDDEN
    ) {
      await router.push(PATH_403);
    } else if (
      error.response?.status === HttpStatusCodes.SEE_OTHER
    ) {
      const redirectUrl = error.response.data;

      return redirectUrl;
    }

    return Promise.reject(error);
  }
);

export default axios;