import { RouteRecordRaw } from "vue-router";
import { RVacancyMatchesBrowse } from "@/pages/Layout/views/Expert/Vacancies/template/views/VacancyMatches/browse/RVacancyMatchesBrowse";
import { RVacancyMatchesTemplate } from "@/pages/Layout/views/Expert/Vacancies/template/views/VacancyMatches/template/RVacancyMatchesTemplate";

export const RVacancyMatches: RouteRecordRaw = {
  path: "match",
  name: "VacancyMatches",
  component: () =>
    import(
      /* webpackChunkName: "vacancy-matches" */
      "./VVacancyMatches.vue"
    ),
  children: [RVacancyMatchesBrowse, RVacancyMatchesTemplate],
};
