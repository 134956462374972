import { RouteRecordRaw } from "vue-router";

export const RCompanyDetails: RouteRecordRaw = {
  path: "company-details",
  name: "VacancyCompanyDetails",
  component: () =>
    import(
      /* webpackChunkName: "vacancy-company-details" */
      "./VCompanyDetails.vue"
    ),
};
