import { RouteRecordRaw } from "vue-router";

export const RCandidateMatchesTemplate: RouteRecordRaw = {
  path: ":match_id(\\d+)",
  name: "CandidateMatchesTemplate",
  component: () =>
    import(
      /* webpackChunkName: "candidate-matches-template" */
      "./VCandidateMatchesTemplate.vue"
    ),
};
