import { RouteRecordRaw } from "vue-router";

export const RJobPaymentError: RouteRecordRaw = {
  path: "error",
  name: "JobPaymentError",
  component: () =>
    import(
      /* webpackChunkName: "job-payment-error" */
      "./VJobPaymentError.vue"
    )
};
