import { RouteRecordRaw } from "vue-router";

export const RQuestionnaire: RouteRecordRaw = {
  path: "questionnaire",
  name: "Questionnaire",
  component: () =>
    import(
      /* webpackChunkName: "questionnaire" */
      "./VQuestionnaire.vue"
    ),
};
