import { RouteRecordRaw } from "vue-router";

export const RCompaniesTemplate: RouteRecordRaw = {
  path: ":company_id(\\d+)",
  name: "ExpertCompaniesTemplate",
  component: () =>
    import(
      /* webpackChunkName: "expert-companies-template" */
      "./VCompaniesTemplate.vue"
    ),
};