import { RouteRecordRaw } from "vue-router";

export const RJobMatchBrowse: RouteRecordRaw = {
  path: "",
  name: "JobMatchBrowse",
  component: () =>
    import(
      /* webpackChunkName: "job-match-browse" */
      "./VJobMatchBrowse.vue"
    )
};
