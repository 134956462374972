import { RouteRecordRaw } from "vue-router";

export const RRecommended: RouteRecordRaw = {
  path: "recommended",
  name: "Recommended",
  component: () =>
    import(
      /* webpackChunkName: "candidate-recommended" */
      "./VRecommended.vue"
    ),
  meta: {
    title: "Recommended",
  },
};