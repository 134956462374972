import { RouteRecordRaw } from "vue-router";

export const RCandidateVacancies: RouteRecordRaw = {
  path: "vacancies",
  name: "CandidateVacancies",
  component: () =>
    import(
      /* webpackChunkName: "candidate-vacancies" */
      "./VCandidateVacancies.vue"
    ),
};
