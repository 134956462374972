import { RouteRecordRaw } from "vue-router";

export const RMyJobs: RouteRecordRaw = {
  path: "jobs",
  name: "MyJobs",
  component: () =>
    import(
      /* webpackChunkName: "candidate-jobs" */
      "./VMyJobs.vue"
    ),
  meta: {
    title: "My Jobs",
  },
};