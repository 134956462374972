import { RouteRecordRaw } from "vue-router";
import { RCandidate } from "@/pages/Layout/views/Candidate/RCandidate";
import { RWelcome } from "@/pages/Layout/views/Welcome/RWelcome";
import { RExpert } from "@/pages/Layout/views/Expert/RExpert";
import { REmployer } from "@/pages/Layout/views/Employer/REmployer";

export const RLayout: RouteRecordRaw = {
  path: "/",
  name: "Layout",
  component: () =>
    import(
      /* webpackChunkName: "layout" */
      "./PLayout.vue"
    ),
  children: [
    {
      path: "/",
      redirect: RWelcome.path
    },
    RWelcome,
    RCandidate,
    RExpert,
    REmployer,
  ]
  // meta: {
  //   auth: true,
  // },
};
